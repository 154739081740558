// ScrollLockContext.js
import React, { createContext, useContext, useState } from 'react';

const ScrollLockContext = createContext();

export const useScrollLock = () => useContext(ScrollLockContext);

export const ScrollLockProvider = ({ children }) => {
  const [isLocked, setIsLocked] = useState(false);

  const lockScroll = () => {
    setIsLocked(true);
    document.body.style.overflow = 'hidden';
  };

  const unlockScroll = () => {
    setIsLocked(false);
    document.body.style.overflow = '';
  };

  return (
    <ScrollLockContext.Provider value={{ isLocked, lockScroll, unlockScroll }}>
      {children}
    </ScrollLockContext.Provider>
  );
};