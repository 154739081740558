import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ProtectedRoute from "./utils/ProtectedRoute";
import {ScrollLockProvider} from './Components/ReactScrollLockContext'


// Lazy load components for better performance
const Home = lazy(() => import("./Components/Home"));
const PromotionalClassDetails = lazy(() => import("./Components/PromotionalClassDetails"));
const JoinNow = lazy(() => import("./Components/JoinNow"));
const Login = lazy(() => import("./Components/Login"));
const OtpVerification = lazy(() => import("./Components/OtpVerification"));
const CreateProfile = lazy(() => import("./Components/CreateProfile"));
const Onboarding = lazy(() => import("./Components/Onboarding"));
const ClassBooking = lazy(() => import("./Components/ClassBooking"));
const ClassDetails = lazy(() => import("./Components/ClassDetails"));
const StudioDetails = lazy(() => import("./Components/StudioDetails"));
const Trainerinfo = lazy(() => import("./Components/Trainerinfo"));
const Slots = lazy(() => import("./Components/Slots"));
const ForgotPassword = lazy(() => import("./Components/ForgotPassword")); // Add Forgot Password Component
const ClassData = lazy(() => import("./Components/classData")); // ClassData Component
const ConfirmBooking = lazy(() => import("./Components/Confirmbooking"));
const Bookedclass = lazy(() => import("./Components/Bookedclass")); // Import Bookedclass
const TransactionHistory = lazy(() => import("./Components/TransactionHistory")); // Import Bookedclass
const BookingList = lazy(() => import("./Components/BookingList")); // Import Bookedclass
const ClassesCarousel = lazy(() => import("./Components/ClassesCarousel"));

const NotFound = () => (
  <div style={{ textAlign: "center", padding: "50px", color: "white" }}>
    <h1>404</h1>
    <p>The page you're looking for doesn't exist.</p>
    <a href="/home">Go to Home</a>
  </div>
);

function App() {
  const { state } = useLocation();

  // Check if the user is not on a mobile device
  //if (!isMobile) {
    //return (
      //<div style={{ textAlign: 'center', padding: '20px' }}>
        //<h1>Access Restricted</h1>
        //<p>This website is only accessible through mobile devices.</p>
      //</div>
    //);
  //}

  return (
    <ScrollLockProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
      {/* Redirect "/" to "/home" */}
      <Route path="/" element={<Navigate to="/home" replace />} />

      {/* Public routes */}
      <Route path="/home" element={<Home />} />
      <Route path="/join" element={<JoinNow />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/login" element={<Login />} />
      <Route path="/otp-verification" element={<OtpVerification />} />
      <Route path="/CreateProfile" element={<CreateProfile />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />

      {/* Route for Promotional Class Details */}
      <Route path="/promotional-class-details" element={<PromotionalClassDetails />} />
      
      {/* Protected routes */}
      <Route
        path="/classdetails"
        element={
          <ProtectedRoute>
            <ClassDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/studio-details/:id"
        element={
          <ProtectedRoute>
            <StudioDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/classbooking"
        element={
          <ProtectedRoute>
            <ClassBooking />
          </ProtectedRoute>
        }
      />
      <Route
        path="/classData"
        element={
          <ProtectedRoute>
            <ClassData />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Trainerinfo"
        element={
          <ProtectedRoute>
            <Trainerinfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/slots"
        element={
          <ProtectedRoute>
            <Slots />
          </ProtectedRoute>
        }
      />
      <Route
        path="/confirmbooking"
        element={
          <ProtectedRoute>
            <ConfirmBooking />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bookedclass"
        element={
          <ProtectedRoute>
            <Bookedclass />
          </ProtectedRoute>
        }
      />
      <Route
        path="/mybookings"
        element={
          <ProtectedRoute>
            <BookingList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/transaction-history"
        element={
          <ProtectedRoute>
            <TransactionHistory />
          </ProtectedRoute>
        }
      />

      {/* Fallback route for undefined paths */}
      <Route path="*" element={<NotFound />} />
    </Routes>
      </Suspense>
      </ScrollLockProvider>
  );
}

export default App;
